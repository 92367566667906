import segmentExecutionModule from '@/services/segmentExecution';
// import segmentModule from '@/services/sessionFilter';
import UserFilterComponent from '@/components/chartFilters/userPropertyFilter/userPropertyFilter.vue';
import moment from 'moment';
import * as _ from 'lodash';
import segmentFormatter from '@/services/segmentFormatter';
import customerServices from '@/services/customer';
import ExportSegmentUsersPopup from '@/pages/segmentation/exportSegmentUsersPopup/exportSegmentUsersPopup';

export default {
  name: 'SegmentQueryStudio',
  components: {
    UserFilterComponent,
    ExportSegmentUsersPopup
  },
  data() {
    return {
      moment: moment,
      fetchingData: true,
      queryList: [],
      submittingQuery: false,

      customerMetadata: null,
      pubsubChannel: null,
      currentActive: -1
    };
  },

  methods: {
    async onShowCount() {
      this.creatingSegment = true;
      try {
        let filter = this.$refs.userFilter.getFilters();
        let result = await segmentExecutionModule.submitQuery(filter);
        let newSegment = this.formatSegmentForDisplaying(result.data);
        this.queryList.unshift(newSegment);
      } catch (err) {
        this.errorToast('Failed to submit query. Please contact support.');
        console.error(err);
      } finally {
        this.submittingQuery = false;
      }
    },

    async onCreateSegment() {
      let info = this.$refs.userFilter.getFilters();
      let requestId = 'create-segment-' + Math.random().toString(16).slice(2);
      window.sessionStorage.setItem(requestId, JSON.stringify(info));

      // Redirect to create segment page with requst id.
      this.$router.push('/segment/create?request=' + requestId);
    },

    onShowSegmentDetails(index) {
      if (this.queryList[index].stage != 'complete') return;

      if (index == this.currentActive) {
        this.currentActive = -1;
      } else {
        this.currentActive = index;
      }
    },

    onExportSegmentUsers(index) {
      let query = this.queryList[index].query;
      this.$refs.exportSegmentUsersPopup.showDialog(query);
    },

    onSegmentOptionClick(index, command) {
      if (command == 'edit') {
        document.getElementById('appDiv').scroll({ top: 0, behavior: 'smooth' });
        let query = this.queryList[index].query;
        this.$refs.userFilter.restFilter(JSON.parse(JSON.stringify(query)));
      } else if (command == 'export') {
        this.onExportSegmentUsers(index);
      } else if (command == 'save') {
        let query = this.queryList[index].query;
        // Redirect to create segment page with requst id.
        let requestId = 'create-segment-' + Math.random().toString(16).slice(2);
        window.sessionStorage.setItem(requestId, JSON.stringify(query));
        this.$router.push('/segment/create?request=' + requestId);
      }
    },

    async getList() {
      this.fetchingData = true;
      try {
        let result = await customerServices.fetchUserPropertyList(null, this);
        this.customerMetadata = result.data.data;

        result = await segmentExecutionModule.getList();
        let list = result.data;
        for (let i = 0; i < list.length; i++) {
          list[i] = this.formatSegmentForDisplaying(list[i]);
        }
        this.queryList = list;
      } catch (e) {
        this.reportError(e);
        this.errorToast('Failed to fetch segments! Please contact support.');
      } finally {
        this.fetchingData = false;
      }
    },

    formatSegmentForDisplaying(segment) {
      segment.query = JSON.parse(segment.query);
      segment.text = segmentFormatter.convertToString(segment.query, this.customerMetadata);
      if (segment.stage == 'complete') {
        segment.result = JSON.parse(segment.result);
      }

      return segment;
    },

    async executeExistingQuery(index) {
      // this.fetchingData = true;
      let query = this.queryList[index];
      document.getElementById('appDiv').scroll({ top: 0, behavior: 'smooth' });
      try {
        this.queryList[index].stage = 'running';
        this.queryList.splice(index, 1);
        this.queryList.unshift(query);

        let result = await segmentExecutionModule.executeQuery(query.id);

        console.log('Execute Query Request response:', result);
      } catch (e) {
        this.reportError(e);
        this.errorToast('Failed to start segment request! Please contact support.');
      } finally {
        // this.fetchingData = false;
      }
    },

    async startPusherListener() {
      let pusher = new window.Pusher('4a955ea4744e1d73e579', {
        cluster: 'ap2'
      });

      let channelName = 'dashboard-' + this.$store.state.token.companyId;
      this.pubsubChannel = pusher.subscribe(channelName);
      this.pubsubChannel.bind('segment-result', (data) => {
        let index = _.findIndex(this.queryList, (qry) => {
          return qry.id == data.id;
        });
        if (index >= 0) {
          this.queryList[index].stage = data.stage;
          this.queryList[index].result = JSON.parse(data.result);
        }
      });
    }
  },

  mounted() {
    this.getList();
    this.startPusherListener();
  },

  beforeDestroy() {
    if (this.pubsubChannel) {
      this.pubsubChannel.unbind('segment-result', null);
    }
  }
};
