const moment = require('moment');
const _ = require('lodash');

const beforValueComparisionTypes = {
  ilike: 'contains',
  '=': 'is',
  'not ilike': 'does not contain',
  '!=': 'is not',
  'is not null': 'exists',
  'is null': 'does not exist',
  between: 'between',
  '>=': 'greater than or equal to',
  '<=': 'less than or equal to',
  contains: 'contains',

  'array-equal-any': '(any of) equals',
  'array-equal-all': '(all of) equals',
  'array-not-equal-any': '(any of) not equals',
  'array-not-equal-all': '(all of) not equals',

  'time-future-greater-than': ' greater than ',
  'time-past-greater-than': ' less than ',
  'time-future-less-than': ' greater than ',
  'time-past-less-than': ' less than '
};

const afterValueComparisionTypes = {
  'time-future-greater-than': 'minutes after current time',
  'time-past-greater-than': 'minutes after current time',
  'time-future-less-than': 'minutes before current time',
  'time-past-less-than': 'minutes before current time'
};

let getDurationString = (durationInfo) => {
  let str = '';
  if (durationInfo.type == 'in_last') {
    str += ` in last `;
    let number = durationInfo.number;
    if (number.comparisonType == 'between') str += `${number.valueRange[0]} to ${number.valueRange[1]} ${number.valueUnit}`;
    else str += `${number.value} ${number.valueUnit}`;
  } else if (durationInfo.type == 'exactly') {
    let number = durationInfo.number;
    str += ` exactly ${number.value} ${number.valueUnit} ago `;
  } else if (durationInfo.type == 'before' || durationInfo.type == 'after') {
    let parsedDate = moment(durationInfo.date.value).format('YYYY-MM-DD HH:mm:ss');
    // let parsedDate = 'sdf';
    str += ` ${durationInfo.type} ${parsedDate} `;
  } else if (durationInfo.type == 'between') {
    // Between two dates
    let dateRange = durationInfo.date_range.value;
    let startDate = ' ';
    let endDate = ' ';
    if (dateRange) {
      startDate = moment(dateRange[0]).format('YYYY-MM-DD HH:mm:ss');
      endDate = moment(dateRange[1]).format('YYYY-MM-DD HH:mm:ss');
    }
    str += ` between (${startDate} to ${endDate}) `;
  }
  return str;
};

let performedSubFilterText = (subFilterList, matchAnyOfSubFilters, isCustomerFilter) => {
  if (subFilterList.length == 0) return '';
  let filterStrList = [];
  for (let i = 0; i < subFilterList.length; i++) {
    let filter = subFilterList[i];

    let value = filter.value;
    if (value == null || value === undefined) filter.value = '';

    let beforeComparision = beforValueComparisionTypes[filter.comparisonType] ? ` ${beforValueComparisionTypes[filter.comparisonType]} ` : '';
    let afterComparision = afterValueComparisionTypes[filter.comparisonType] ? ` ${afterValueComparisionTypes[filter.comparisonType]} ` : '';
    filterStrList.push(`${filter.propertyName}${beforeComparision}${filter.value}${afterComparision}`);
  }

  let str = '';
  let joinBy = null;
  if (matchAnyOfSubFilters) joinBy = 'OR';
  else joinBy = 'AND';

  if (isCustomerFilter) joinBy = `<strong>${joinBy}</strong>`;

  str = filterStrList.join(` ${joinBy} `);
  return str;
};

exports.convertToString = (input, customerMetadata) => {
  let segmentQuery = JSON.parse(JSON.stringify(input));
  let list = [];

  if (!segmentQuery.filters) {
    return 'Invalid Query';
  }

  if (segmentQuery.filters.length == 0 && segmentQuery.performedFilter.length == 0 && segmentQuery.notPerformedFilter.length == 0) {
    return 'All Customers';
  }

  if (segmentQuery.filters.length > 0) {
    for (let i = 0; i < segmentQuery.filters.length; i++) {
      let filter = segmentQuery.filters[i];
      // Replace column name with customer metadata.
      let col = _.find(customerMetadata, (mt) => {
        return mt.columnName == filter.propertyName;
      });
      filter.propertyName = col ? col.name : filter.propertyName;
    }

    let str = performedSubFilterText(segmentQuery.filters, !segmentQuery.matchAll, true);
    list.push(str);
  }

  // Performed
  for (let i = 0; i < segmentQuery.performedFilter.length; i++) {
    let filterObj = segmentQuery.performedFilter[i];

    // Add Property Name
    let filter = `Performed ${filterObj.eventName} `;
    filter += `${filterObj.count} time${filterObj.count > 1 ? 's' : ''}`;
    filter += `${getDurationString(filterObj.duration)} `;

    // Add Filters
    let subFiltersString = performedSubFilterText(filterObj.filters, filterObj.matchAnyOfSubFilters, false);
    if (filterObj.filters.length > 0) filter += `with attributes (${subFiltersString})`;

    list.push(filter);
  }

  // Not Performed
  for (let i = 0; i < segmentQuery.notPerformedFilter.length; i++) {
    let filterObj = segmentQuery.notPerformedFilter[i];

    // Add Property Name
    let filter = `Not Performed ${filterObj.eventName} `;
    filter += `${getDurationString(filterObj.duration)} `;

    // Add Filters
    let subFiltersString = performedSubFilterText(filterObj.filters, filterObj.matchAnyOfSubFilters, false);
    if (filterObj.filters.length > 0) filter += `with attributes (${subFiltersString})`;

    list.push(filter);
  }

  for (let i = 0; i < list.length; i++) {
    list[i] = `<div class="inline-block rounded-md bg-gray-100 my-1 px-2.5 py-1">${list[i]}</div>`;
  }

  if (segmentQuery.matchAll) return list.join('&nbsp;<span class="font-semibold bg-white">AND</span>&nbsp;');
  else return list.join('&nbsp;<span class="font-semibold  bg-white">OR</span>&nbsp;');
};
