const axios = require('axios');
const { baseUrlNew, buildAuthHeaders } = require('./settings.js');

exports.getList = function (params) {
  return axios.get(baseUrlNew + `segment-execution/`, params, {
    headers: buildAuthHeaders()
  });
};

exports.submitQuery = function (params) {
  return axios.post(baseUrlNew + `segment-execution/`, params, {
    headers: buildAuthHeaders()
  });
};

exports.executeQuery = function (queryId) {
  return axios.post(
    baseUrlNew + `segment-execution/${queryId}/query`,
    {},
    {
      headers: buildAuthHeaders()
    }
  );
};

exports.executeQueryForSegment = function (segmentId) {
  return axios.post(
    baseUrlNew + `segment-execution/segment/${segmentId}/query`,
    {},
    {
      headers: buildAuthHeaders()
    }
  );
};
